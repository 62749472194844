<template>
  <div>
    <div>
      <div class="container mt-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.search') }}</li>
          </ol>
        </nav>
        <div class="row">
          <news :data="newsData" :key="updatetKey" v-if="newsData.length > 0" />
          <posts :data="postsData" :key="updatetKey" v-if="postsData.length > 0" />
          <bibs :data="bibData" :key="updatetKey" v-if="bibData.length > 0" />
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <div v-if="loading" class="spinner-grow text-primary" role="status"></div>
            </div>
          </div>
          <div class="col-12">
            <div v-if="showEmpty" class="d-flex justify-content-center">
              <a-empty
                :image="Empty.PRESENTED_IMAGE_SIMPLE"
                :image-style="{
                  height: '60px',
                }"
              >
                <template #description>
                  <span>
                    {{ $t('search.noResult') }}
                  </span>
                </template>
              </a-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
/* eslint-disable */
//Vuejs slides
import News from './sections/news'
import Posts from './sections/posts'
import Bibs from './sections/bibs'
import axios from 'axios'
import { Empty } from 'ant-design-vue'

export default {
  name: 'search',
  components: { Bibs, News, Posts },
  data: () => ({
    Empty,
    apiUrl: process.env.VUE_APP_API_URL,
    postsData: [],
    bibData: [],
    newsData: [],
    debatesData: [],
    loading: true,
    showEmpty: false,
    updatetKey: 0,
  }),
  computed: {
    displayedData() {
      return this.paginate(this.data)
    },
  },
  created() {
    this.getData()
    this.forceRerender()
  },
  watch: {
    /*'$route.params.search': {
      handler: function (search) {
        window.location.reload()
      },
      immediate: true,
    },*/
    '$route.params.search': function(newS, oldS) {
      this.getData()
      this.forceRerender()
    },
    '$route.params.cat': function(newS, oldS) {
      this.getData()
      this.forceRerender()
    },
  },
  methods: {
    forceRerender() {
      this.updatetKey += 1
    },
    getData() {
      let search = this.$route.params.search
      let cat = this.$route.params.cat
      axios
        .post(this.apiUrl + '/public/search', { type: cat, text: search })
        .then(res => {
          let data = res.data
          if (cat != 'all') {
            for (let i = 0; i < data.length; i++) {
              data[i].category = data[i].category[0]
            }
          }

          if (cat == 'news') {
            this.newsData = data
          } else if (cat == 'posts') {
            this.postsData = data
          } else if (cat == 'bib') {
            this.bibData = data
          } else if (cat == 'debates') {
            this.debatesData = data
          } else {
            if (data.debates) {
              this.debatesData = data.debates
            }
            if (data.news) {
              for (let i = 0; i < data.news.length; i++) {
                data.news[i].category = data.news[i].category[0]
              }
              this.newsData = data.news
            }
            if (data.posts) {
              for (let i = 0; i < data.posts.length; i++) {
                data.posts[i].category = data.posts[i].category[0]
              }
              this.postsData = data.posts
            }
            if (data.bib) {
              for (let i = 0; i < data.bib.length; i++) {
                data.bib[i].category = data.bib[i].category[0]
              }
              this.bibData = data.bib
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
          if (
            this.postsData.length == 0 &&
            this.newsData.length == 0 &&
            this.debatesData.length == 0
          ) {
            this.showEmpty = true
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
  text-align: left;
}

/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}
</style>
